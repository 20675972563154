// store
import { appStatusGuardStore, appStatusStore, channelStore, noticeStore, guardCalendarStore, studentStore, unreadCountStore, unreadMessageCountStore, reservedEventStore } from '~/store/'
// type
import type { F21017Params } from '~~/types/F21017Params'

import * as Sentry from '@sentry/browser'

export function storeSetting () {
  // 未対応メッセージ取得処理
  unreadMessageCountStore.loadUnreadMessageCount()
}

export async function storeSettingHome () {
  // ログイン情報取得
  await appStatusStore.loadInitAppStatus()

  // Studentデータ
  const studentParams: F21017Params = {
    schoolYear: '',
    week: '',
    status: '',
  }
  await studentStore.getStudentDatas(studentParams).then((result) => {
    studentStore.setStudents(result)
  }).catch((error) => {
    // Sentryにエラーを送信
    Sentry.captureException(error)
  })
  // Noticeデータ
  await noticeStore.loadNoticeDatas()
}

export function resetStore() {
  studentStore.resetStudentList()
  noticeStore.resetNoticeList()
  unreadMessageCountStore.resetUnreadMessageCount()
  appStatusStore.resetAppStatus()
  guardCalendarStore.resetCalendarDataList()
}

// 保護者用Store設定
export function storeSettingGuard() {
  // マナミルチャンネル
  channelStore.resetChannelList()
  channelStore.loadChannelList()
}

// 保護者用Store設定(Home画面用)
export async function storeSettingGuardHome(stuId: string) {
  // 生徒選択画面／切り替えモーダルを経由した場合selectedStuIdは設定されている
  if (appStatusGuardStore.getAppStatusGuard.selectedChild.userId == '') {
    await appStatusGuardStore.loadChildren().then(() => {
      // stuIdはプッシュ通知タップからログインした場合にのみ設定されており、undefinedの場合は単一生徒が紐づく保護者のログイン
      let selectedChild
      if(stuId != undefined) {
        const filteredChild = appStatusGuardStore.getAppStatusGuard.children.filter(child => child.userId == stuId)[0]
        selectedChild = filteredChild != undefined ? filteredChild : appStatusGuardStore.getAppStatusGuard.children[0]
      } else {
        selectedChild = appStatusGuardStore.getAppStatusGuard.children[0]
      }
      appStatusGuardStore.setSelectedChild(selectedChild)
    }).catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    })
  }
}

// 保護者用Storeリセット
export function resetStoreGuard() {
  appStatusGuardStore.resetAppStatusGuard()
  channelStore.resetChannelList()
  unreadCountStore.resetChannelCount()
  unreadCountStore.resetEventCount()
  unreadCountStore.resetNoticeCount()
  reservedEventStore.resetReservedEvents()
}
